.navigation {
  padding: 15px 0px;
  width: 100%;
  background-color: #fff;
  min-height: 50px;
  &--logo {
    img {
      margin: 0px auto;
      max-height: 50px;
    }
  }
  &--name {
    font-size: 22px;
    line-height: 50px;
    margin: 0px;
  }
}

.navbar {
  margin-bottom: 0px;
  border: 0px;
}

.page-title {
  background-color: #38393c;
  min-height: 30px;
  padding: 10px 0px;
  margin-bottom: 30px;
  h1 {
    color: #fff;
    margin: 0px;
  }
}

.action-bar {
  padding: 15px 0px;
}

.models--actions {
  margin-bottom: 15px;
}

.btn-label {
  margin-right: 5px;
}

.breadcrumb {
  background-color: #fff;
}

.document-box {
  background-color: #fff;
  padding: 15px; 
  text-align: center;
  -webkit-box-shadow: 1px 1px 5px 0px #ebe2e2;
  -moz-box-shadow: 1px 1px 5px 0px #ebe2e2;
  box-shadow: 1px 1px 5px 0px #ebe2e2;
  &--filename {
    font-size: 16px;
  }
}

.btn-clear {
  background-color: transparent;
  border-color: transparent;
  margin: 0px;
  padding: 0px;
  color: #7aa8e8; 
  &:hover, &:focus, &:active {
    background-color: transparent;
    border-color: transparent;
    color: #7aa8e8; 
  }
}

.white-box {
  background-color: #fff;
  padding: 15px;
  &--header {
    border-bottom: 1px solid #ddd;
    h2 {
      display: inline-block;
      margin-top: 0px;
    }
    .btn {
      display: inline-block;
      vertical-align: top;
      float: right;
    }
  }
}

.login-box {
  .panel-heading {
    border: 0px;
    img {
      margin: 0px auto;
      width: 100px;
    }
  }
}

.login-title {
  text-align:center;
  h1 {
    font-size: 26px;
  }
}

.col-md-offset-4-5 {
  margin-left: 38%;
}

.margin-top {
  margin-top: 30px;
}

.dashboard-section-title {
  margin-left: 15px;
}

.new-folder {
  display: none;
}

.filter-dropdown {
  float: left !important;
  color: #fff;
  background-color: #38393c;
  margin-bottom: 10px !important;
  a {
    color: #fff;
    background-color: #38393c !important;
    border-color: #38393c !important;
  }
  .dropdown-menu {
    a {
      background-color: #fff !important;
      border-color: #fff !important;
    }
  }
  &:hover {
    background-color: #38393c;
    border-color: #38393c;
  }
  &:active {
    background-color: #38393c;
    border-color: #38393c;
  }
  &:focus {
    background-color: #38393c;
    border-color: #38393c;
  }
}
